<template>
  <v-container>
    <div v-if="module">
      <v-form ref="form">
        <v-row>
          <v-col cols="6">
            <v-row>
              <v-col>
                <div v-for="(info, name) in module.config.input" :key="name">
                  <v-text-field
                    v-model="parameters[name]"
                    :rules="[(v) => name == 'priority_fragment' || !!v || 'This field is required']"
                    validate-on-blur
                    required
                  >
                    <template #label>
                      <div v-html="info.description" />
                    </template>
                    <template #append>
                      <v-btn
                        v-if="name == 'smiles'"
                        color="primary"
                        @click="loadStructure"
                      >
                        {{ drawerBtnText }}
                      </v-btn>
                    </template>
                  </v-text-field>
                  <div v-show="name == 'smiles' && isDrawnCompound">
                    <v-btn
                      color="primary"
                      @click="convertToSmiles"
                    >
                      Reflect structure
                    </v-btn>
                    <CompoundEditor />
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="6">
            <div class="ml-2 mb-1">
              <v-btn color="warning" @click="getStructure"> Display molecule with indices </v-btn>
            </div>
            <div class="structure" v-html="structure" />
          </v-col>
        </v-row>
      </v-form>
      <ExecuteButton :disabled="!canSubmit" :validation-method="validate" :confirm-loading="loading" @confirm="onSubmit" />
      <EstimatedRunTime module="EMPIRE" />
    </div>
  </v-container>
</template>

<script>
import router from '@/router';
import Swal from 'sweetalert2';
import ExecuteButton from '@/components/ExecuteButton.vue';
import CompoundEditor from '@/components/CompoundEditor.vue';
import { convertToSentenceCase } from '@/mixins/utils';
import { Kekule } from 'kekule';
import EstimatedRunTime from '@/components/EstimateRunTime.vue';

export default {
  name: 'ExecuteFormEMPIRE',
  components: {
    ExecuteButton,
    CompoundEditor,
    EstimatedRunTime
  },
  data() {
    return {
      id: this.$route.params.id,
      module: null,
      structure: '',
      canSubmit: true,
      hasStructure: false,
      parameters: Object(),
      files: Object(),
      isDrawnCompound: false,
      loading: false,
      input: { 'Generation method': 'Empire' }
    };
  },
  computed: {
    drawerBtnText() {
      return this.isDrawnCompound ? 'Hide Drawer' : 'Use Drawer';
    }
  },
  mounted() {
    const self = this;
    this.api.getMLModuleByName(
      'EMPIRE',
      function(module) {
        self.module = module;
        self.module.config = JSON.parse(self.module.config);
        for (const [name, info] of Object.entries(self.module.config.input)) {
          self.parameters[name] = info.default ?? '';
        }
      },
      function(error) {
        console.log(error);
      }
    );
  },
  methods: {
    convertToSentenceCase,
    onSubmit(visibility) {
      this.loading = true;
      const self = this;
      Object.entries(this.module.config.input).forEach(([name, info]) => {
        if (self.parameters[name]) {
          self.input[info.description] = self.parameters[name];
        }
      });
      this.api.executeMLModule(
        { name: 'EMPIRE' },
        { ...self.parameters, ...visibility },
        {},
        function(response) {
          router.push({
            name: 'LeadGenerationEMPIREResultView',
            params: { id: response.id }
          });
          self.loading = false;
        },
        function(error) {
          console.log(error);
          self.loading = false;
        },
        this.input
      );
    },
    async getStructure() {
      const self = this;
      await this.api.getSvgFromSmiles(
        self.parameters.smiles,
        function(response) {
          self.structure = response;
          self.hasStructure = true;
        },
        function(error) {
          self.hasStructure = false;
          self.structure = '';
          Swal.fire({
            icon: 'error',
            title: error.data
          });
        }
      );
    },
    async validate() {
      await this.getStructure();
      return this.$refs.form.validate() && this.hasStructure;
    },
    async loadStructure() {
      const composer = Kekule.Widget.getWidgetOnElem(
        document.getElementById('kekule')
      );
      const molBlock = await this.api.getMolBlockFromSmiles(this.parameters.smiles);
      if (molBlock === '') {
        Swal.fire({
          icon: 'error',
          title: 'Invalid SMILES'
        });
        return;
      }
      composer.setChemObj(Kekule.IO.loadFormatData(molBlock, 'mol'));
      this.isDrawnCompound = !this.isDrawnCompound;
    },
    convertToSmiles() {
      const composer = Kekule.Widget.getWidgetOnElem(
        document.getElementById('kekule')
      );
      const smiles = Kekule.IO.saveFormatData(
        composer.getChemObj(),
        'smi'
      );
      this.parameters.smiles = smiles;
      this.isDrawnCompound = !this.isDrawnCompound;
    }
  }
};
</script>

<style scoped>
.structure {
  width: 310px;
  height: 310px;
  border: 1px solid black;
}
</style>
