<template>
  <v-container>
    <div>
      <v-form ref="form">
        <v-row>
          <v-col cols="4">
            <v-select
              v-model="casVaeMethod"
              :items="casVaeMethods"
              item-value="value"
              item-text="text"
              label="Generation method"
              required
              :rules="[v => !!v || 'The prediction method is required']"
            >
              <template #append-outer>
                <v-tooltip right>
                  <template #activator="{ on, attrs }">
                    <v-icon class="help-icon" v-bind="attrs" v-on="on">
                      mdi-help-circle-outline
                    </v-icon>
                  </template>
                  <span v-html="methodHelp" />
                </v-tooltip>
              </template>
            </v-select>
          </v-col>
        </v-row>
        <v-row v-if="casVaeMethod !== ''">
          <v-col cols="4">
            <v-select
              v-model="property"
              :items="properties"
              item-value="value"
              item-text="text"
              label="Desired property"
              required
              :rules="[v => !!v || 'The desired property is required']"
            />
          </v-col>
          <v-col cols="4">
            <v-text-field
              v-model="epochs"
              label="Epoch"
              type="number"
              min="1"
              required
              :rules="[v => !!v || 'The type of the input is required']"
            />
          </v-col>
        </v-row>
        <v-row v-if="property=='pIC50'">
          <v-col cols="6">
            <ModuleInput
              :info="{
                description:
                  'A QSAR model file (Pickle format)',
                type: 'file',
              }"
              filled
              dense
              accept=".pickle"
              name="qsar_model"
              @setFile="onSetFile"
            />
          </v-col>
          <v-col class="d-flex align-center">
            <sample-download-button :filename="module.config.sample_file" />
          </v-col>
        </v-row>
        <v-row>
          <v-col v-if="casVaeMethod === 'bcasvae'" cols="4">
            <v-text-field
              v-model="token"
              name="amplify_token"
              label="Amplify token"
              required
              :rules="[v => !!v || 'The type of the input is required']"
            >
            <template #append-outer>
                <v-tooltip right>
                  <template #activator="{ on, attrs }">
                    <v-icon class="help-icon" v-bind="attrs" v-on="on">
                      mdi-help-circle-outline
                    </v-icon>
                  </template>
                  <span>Please input the amplify token. </span>
                </v-tooltip>
              </template>
            </v-text-field>
          </v-col>
        </v-row>
        <ExecuteButton
          :disabled="!canSubmit"
          :validation-method="validate"
          :confirm-loading="loading"
          @confirm="onSubmit"
        />
        <div v-if="casVaeMethodLabel">
          <EstimatedRunTime :module="casVaeMethodLabel" />
        </div>
      </v-form>
    </div>
  </v-container>
</template>

<script>
import router from '@/router';
import ExecuteButton from '@/components/ExecuteButton.vue';
import ModuleInput from '@/components/ModuleInput';
import SampleDownloadButton from '@/components/SampleDownloadButton.vue';
import EstimatedRunTime from '@/components/EstimateRunTime.vue';

export default {
  name: 'ReactionGenerationExecuteView',
  components: {
    ModuleInput,
    ExecuteButton,
    SampleDownloadButton,
    EstimatedRunTime
  },
  data() {
    return {
      id: this.$route.params.id,
      module: null,
      parameters: Object(),
      files: Object(),
      fileSelected: false,
      property: 'qed',
      epochs: 1,
      properties: [
        { text: 'QED', value: 'qed' },
        { text: 'logP', value: 'logp' },
        { text: 'Bioactivity', value: 'pIC50' }
      ],
      loading: false,
      input: {},
      casVaeMethod: this.$route.query.casVaeMethod || '',
      casVaeMethods: [
        { text: 'CasVAE', value: 'casvae' },
        { text: 'bCasVAE', value: 'bcasvae' }
      ],
      token: ''
    };
  },
  computed: {
    canSubmit: function() {
      if (this.property === 'pIC50') {
        return this.fileSelected;
      } else if (this.casVaeMethod === '') {
        return false;
      } else if (this.casVaeMethod === 'bcasvae' && this.token === '') {
        return false;
      } else {
        return true;
      }
    },
    moduleName: function() {
      return this.casVaeMethod === 'bcasvae' ? 'bCasVAE' : 'CasVAE';
    },
    methodHelp: function() {
      switch (this.casVaeMethod) {
      case '':
        return 'Please choose a generation method.';
      case 'bcasvae':
        return `This model outputs the structures of molecules with the desired property and <br>
                  the potential synthetic routes of the molecules,  <br>
                  using binary casVAE with reaction templates.
               `;
      case 'casvae':
        return `This model outputs the structures of molecules <br>
                  with the desired property and the potential synthetic routes of the molecules, <br>
                  using casVAE with reaction templates.`;
      }
      return '現在使用できません。';
    },
    casVaeMethodLabel: function() {
      return this.casVaeMethods.find(method => method.value === this.casVaeMethod)?.text;
    }
  },
  watch: {
    casVaeMethod: function(val) {
      this.epochs = val === 'bcasvae' ? 200 : 1;
    }
  },
  mounted() {
    this.checkLoggedIn(this.$session);
    this.loadModule();
  },
  methods: {
    validate() {
      return this.$refs.form.validate();
    },
    loadModule() {
      const self = this;
      this.api.getMLModuleByName(
        this.moduleName,
        function(module) {
          self.module = module;
          const config = JSON.parse(module.config);
          self.module.config = config;
        },
        function(error) {
          console.log(error);
        }
      );
    },
    onSetFile(...args) {
      const [name, file] = args;
      if (file) {
        this.files[name] = file;
        this.fileSelected = true;
        this.input[name] = file.name;
      } else {
        this.fileSelected = false;
      }
    },
    onSubmit(visibility) {
      this.loading = true;
      this.$session.start();
      const self = this;
      this.input = {
        ...this.input,
        'Desired property': this.properties.find(prop => prop.value === this.property).text,
        'Epoch': this.epochs
      };
      const executeOptions = {
        'property': this.property,
        'epoch': this.epochs,
        ...visibility
      };
      if (this.casVaeMethod === 'bcasvae') {
        executeOptions.token = this.token;
        this.input['Amplify token'] = this.token.length > 5
          ? this.token.slice(0, 5) + '*'.repeat(this.token.length - 5)
          : '****';
      }
      this.api.executeMLModule(
        { name: this.moduleName },
        executeOptions,
        self.files,
        function(response) {
          router.push({
            name: 'ReactionGenerationResultView',
            params: { id: response.id }
          });
          self.loading = false;
        },
        function(error) {
          console.log(error);
          self.loading = false;
        },
        this.input
      );
    }
  }
};
</script>
