<template>
  <div class="estimated-run-time">
    <v-icon color="grey darken-2" small>mdi-clock-outline</v-icon>
    <span class="run-time-text">estimated run time: {{ estimatedRunTime || 'unmeasured' }}</span>
  </div>
</template>

<script>
import api from '@/api';

export default {
  name: 'EstimatedRunTime',
  props: {
    predictionMethod: {
      type: String,
      default: ''
    },
    module: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      estimatedRunTime: null
    };
  },
  watch: {
    predictionMethod(newVal) {
      this.fetchEstimatedRunTime(newVal, this.module);
    },
    module(newVal) {
      this.fetchEstimatedRunTime(this.predictionMethod, newVal);
    }
  },
  mounted() {
    this.fetchEstimatedRunTime(this.predictionMethod, this.module);
  },
  methods: {
    formatExecutionTime(seconds) {
      if (seconds === 0) {
        return 'unmeasured';
      }
      const h = Math.floor(seconds / 3600);
      const m = Math.floor((seconds % 3600) / 60);
      const s = Math.floor(seconds % 60);
      return `${h > 0 ? h + ' hours, ' : ''}${m} mins, ${s} seconds`;
    },
    fetchEstimatedRunTime(predictionMethod, module) {
      api.getExecutionTimes(
        module,
        predictionMethod,
        (runtime) => {
          this.estimatedRunTime = this.formatExecutionTime(runtime.execution_time);
        },
        () => {
          this.estimatedRunTime = 'unmeasured';
        }
      );
    }
  }
};
</script>

<style scoped>
.estimated-run-time {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #616161;
}

.run-time-text {
  margin-left: 8px;
}
</style>
