<template>
  <v-container>
    <v-form ref="form">
      <v-row>
        <v-col cols="8">
          <ModuleInput
            :info="{
            description:
              'A compound-induced gene expression signature (CSV format)',
            type: 'file',
          }"
          filled
          required
          dense
          accept=".csv"
          name="gene_file"
            :rules="[v => !!v || 'A gene expression signature is required']"
            @setFile="onSetFile"
          />
          <v-row no-gutters justify="end">
            <sample-download-button
              v-if="hasSampleFile"
              :filename="config.sample_file"
            />
          </v-row>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="4">
          <v-select
            v-model="predictiveModel"
            :items="predictiveModels"
            item-value="value"
            item-text="text"
            label="Predictive model selection"
            required
            :rules="[v => !!v || 'The predictive method is required']"
          >
            <template #append-outer>
              <v-tooltip right>
                <template #activator="{ on, attrs }">
                  <v-icon class="help-icon" v-bind="attrs" v-on="on"
                    >mdi-help-circle-outline</v-icon
                  >
                </template>
                <span v-html="modelHelp" />
              </v-tooltip>
            </template>
          </v-select>
        </v-col>
      </v-row>
      <div v-if="predictiveModel !== ''">
        <div>
          <v-row>
            <v-col cols="4">
            <v-select
              v-model="predictionMethod"
              :items="predictionMethods"
              item-text="label"
              item-value="value"
              label="Prediction method"
              required
              :rules="[v => !!v || 'The prediction method is required']"
              >
                <template #append-outer>
                  <v-tooltip right>
                    <template #activator="{ on, attrs }">
                    <v-icon class="help-icon" v-bind="attrs" v-on="on"
                      >mdi-help-circle-outline</v-icon
                    >
                    </template>
                    <span v-html="methodHelp" />
                  </v-tooltip>
                </template>
              </v-select>
            </v-col>
          </v-row>
          <v-row v-if="predictiveModel == 'custom'">
            <v-col cols="4">
            <v-select
              v-model="signature"
              :items="signatures"
              :item-text="
                (item) => convertToSentenceCase(item.key)
              "
              item-value="key"
              label="Cell / Tissue selection"
              return-object
              :required="predictiveModel === 'custom'"
              :rules="[v => !!v || 'The type of the input is required']"
              @change="changeKey"
            />
            </v-col>
            <v-col cols="4">
            <v-autocomplete
              v-show="targets.length > 0"
              v-model="selected"
              :items="targets"
              :label="autocompleteLabel"
            >
                <template slot="selection" slot-scope="{ item }">
                  {{ convertToSentenceCase(item) }}
                </template>
                <template slot="item" slot-scope="{ item }">
                  {{ convertToSentenceCase(item) }}
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4">
            <v-select
              v-model="geneAnnotation"
              :items="geneAnnotations"
              label="Gene Annotation"
              required
              :rules="[v => !!v || 'The type of the input is required']"
            />
            </v-col>
          </v-row>
        </div>

      <ExecuteButton
        :disabled="!canSubmit"
        :validation-method="validate"
        :confirm-loading="loading"
        @confirm="onSubmit"
      />
      </div>
      <EstimatedRunTime module="Target Prediction" />
    </v-form>
  </v-container>
</template>

<script>
import router from '@/router';
import ModuleInput from '@/components/ModuleInput';
import { convertToSentenceCase } from '@/mixins/utils';
import ExecuteButton from '@/components/ExecuteButton.vue';
import SampleDownloadButton from '@/components/SampleDownloadButton.vue';
import EstimatedRunTime from '@/components/EstimateRunTime.vue';

export default {
  name: 'TargetPredictionExecuteView',
  components: {
    ModuleInput,
    ExecuteButton,
    SampleDownloadButton,
    EstimatedRunTime
  },
  data() {
    return {
      id: this.$route.params.id,
      config: Object(),
      parameters: Object(),
      files: Object(),
      signatures: [],
      predictionMethods: [
        { label: 'Sparse logistic regression (default)', value: 'regression' },
        { label: 'Similarity search', value: 'similarity' }
      ],
      targets: [],
      predictionMethod: '',
      selected: '',
      fileSelected: false,
      LINCStype: 'original',
      geneAnnotation: '',
      geneAnnotations: ['gene_symbol', 'kegg_id'],
      loading: false,
      signature: null,
      input: {},
      predictiveModel: this.$route.query.predictiveModel || "",
      predictiveModels: [
        { text: "Public data model (default)", value: "public" },
        { text: "Experiment data model", value: "experiment" },
        { text: "Customized model", value: "custom" },
      ],
      token: "",
    };
  },
  computed: {
    canSubmit: function() {
      return this.predictionMethod !== '' &&
        (this.predictiveModel !== 'custom' || this.selected !== '') &&
        this.fileSelected &&
        this.geneAnnotation !== '';
    },
    hasSampleFile: function() {
      return !!this.config.sample_file;
    },
    autocompleteLabel() {
      if (this.signature) {
        if (this.signature.key === 'cell_lines' || this.signature.key === 'Cell lines (imputed)') {
          return 'Cell';
        } else if (this.signature.key === 'tissues' || this.signature.key === 'Tissues (imputed)') {
          return 'Tissue';
        }
      }
      return 'Target';
    },
    predictionMethodLabel: function() {
      return this.predictionMethods.find(obj => obj.value === this.predictionMethod)?.label;
    },
    modelHelp: function () {
      switch (this.predictiveModel) {
        case "":
          return "Please choose a predictive model.";
        case "public":
          return `This model outputs the target proteins of a query compound <br>
            using pre-built models based on public data.`;
        case "experiment":
          return `This model outputs the target proteins of a query compound <br>
            using pre-built models based on the original experimental data.`;
        case "custom":
          return `This model outputs the target proteins of a query compound <br>
            by constructing customized models based on public data.`;
      }
      return "現在使用できません。";
    },
    methodHelp: function () {
      switch (this.predictionMethod) {
        case "":
          return "Please choose a prediction method.";
        case "regression":
          return `
            This model outputs the target proteins of a query compound <br>
            using the sparse logistic regression model for each protein.`;
        case "similarity":
          return `
            This model outputs the target proteins of a query compound based on <br>
            the similarity of gene expression patterns between the query compound and <br>
            compounds with known target proteins.`;
      }
      return "現在使用できません。";
    },
  },
  mounted() {
    this.checkLoggedIn(this.$session);
    const self = this;
    this.api.getMLModuleByName(
      'Target Prediction',
      function(module) {
        self.config = JSON.parse(module.config);
        const optionsData = self.config.input.signatures.options;
        Object.keys(optionsData).forEach((option) => {
          self.signatures.push({
            key: option,
            targets: optionsData[option].values || []
          });
        });
      },
      function(error) {
        console.log(error);
      }
    );
  },
  methods: {
    convertToSentenceCase,
    onSetFile(...args) {
      const [name, file] = args;
      if (file) {
        this.files[name] = file;
        this.fileSelected = true;
        this.input[name] = file.name;
      } else {
        this.fileSelected = false;
      }
    },
    validate() {
      return this.$refs.form.validate();
    },
    onSubmit(visibility) {
      this.loading = true;
      this.$session.start();
      const parameters = {
        target: this.selected,
        predictiveMethod: this.predictiveModel,
        method: this.predictionMethod,
        LINCStype: this.LINCStype,
        genetype: this.geneAnnotation
      };
      this.input = {
        ...this.input,
        'Predictive method': this.predictiveModels.find(obj => obj.value === this.predictiveModel).text,
        'Prediction method': this.predictionMethods.find(obj => obj.value === this.predictionMethod).label,
        'Gene Annotation': this.geneAnnotation
      };
      if (this.predictiveModel == 'custom') {
        this.input = {
          ...this.input,
          'Cell / Tissue selection': convertToSentenceCase(this.signature.key),
        };
        this.input[this.autocompleteLabel] = this.selected;
      }

      this.api.executeMLModule(
        { name: 'Target Prediction' },
        { ...parameters, ...visibility },
        this.files,
        function(response) {
          router.push({
            name: 'TargetPredictionResultView',
            params: { id: response.id }
          });
          self.loading = false;
        },
        function(error) {
          console.log(error);
          self.loading = false;
        },
        this.input
      );
    },
    changeKey(e) {
      if (e.key.includes('imputed')) {
        this.LINCStype = 'imputed';
      } else {
        this.LINCStype = 'original';
      }
      if (e.key.toLowerCase().startsWith('cell')) {
        this.targets = this.signatures.find(s => s.key === 'cell_lines').targets;
      } else if (e.key.toLowerCase().startsWith('tissue')) {
        this.targets = this.signatures.find(s => s.key === 'tissues').targets;
      } else {
        this.targets = [];
      }
      this.selected = this.targets[0] || null;
    }
  }
};
</script>
