<template>
  <v-container>
    <div v-if="execution">
      <ResultCard
        :execution="execution"
        :outputs="outputs"
        @downloadOutputFile="downloadOutputFile"
        @refresh="updateExecution"
      />
      <v-card v-if="densityInfo || matchingGenomeInfo">
        <v-card-text class="black--text">
            <div v-if="densityInfo">{{ densityInfo }}</div>
            <div v-if="matchingGenomeInfo">{{ matchingGenomeInfo }}</div>
        </v-card-text>
      </v-card>
      <div v-if="execution['status'] == 'COMPLETE'">
        <CsvItemList model="tp" :csv-data="csvData" csv-item-type="Protein" />
      </div>
    </div>
  </v-container>
</template>

<script>
import CsvItemList from '@/components/CsvItemList';
import ResultCard from '@/components/Yamanishi/ResultCard';
import { showWaitingDialog } from '@/mixins/utils';

export default {
  name: 'TargetPredictionResultView',
  components: {
    CsvItemList,
    ResultCard
  },
  data() {
    return {
      id: this.$route.params.id,
      timer: null,
      execution: null,
      lastUpdated: null,
      outputs: [],
      csvData: null,
      isDisplayedDialog: false,
      densityInfo: '',
      matchingGenomeInfo: ''
    };
  },
  watch: {
    exectuion: {
      handler: function() {
      },
      deep: true
    },
    compounds: {
      handler: function() {
      },
      deep: true
    }
  },
  mounted() {
    this.checkLoggedIn(this.$session);
    this.observeExecution();
  },
  destroyed() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
  methods: {
    updateExecution() {
      const self = this;
      self.api.getExecution(self.id, function(execution) {
        self.lastUpdated = Date.now();
        self.execution = execution;
        if (execution.end_at) {
          clearInterval(self.timer);
          if (execution.status === 'COMPLETE') {
            self.setOutput();
            self.loadCompounds();
            self.getDensityInfo();
            self.getMatchingGenomeInfo();
          }
        } else {
          if (!self.isDisplayedDialog) {
            showWaitingDialog();
            self.isDisplayedDialog = true;
          }
        }
      }, function(error) {
        console.log(error);
      });
    },
    observeExecution() {
      this.updateExecution();
      this.timer = setInterval(this.updateExecution, 30 * 1000);
    },
    setOutput() {
      const config = JSON.parse(this.execution.module.config);
      this.outputs = config.output;
    },
    loadCompounds() {
      const self = this;
      this.api.loadExecutionCsv(self.id, function(csvData) {
        self.csvData = csvData;
      }, function(error) {
        console.log(error);
      });
    },
    downloadOutputFile(file) {
      this.api.downloadExecutionFile(this.id, file);
    },
    getDensityInfo() {
      const self = this;
      this.api.readDensityFileOfTp(
        this.id,
        function(data) {
          self.densityInfo = data;
        }
      );
    },
    getMatchingGenomeInfo() {
      const self = this;
      this.api.readMatchingGenomeFile(
        this.id,
        function(data) {
          self.matchingGenomeInfo = data;
        }
      );
    }
  }
};
</script>
<style scoped>
    button {
        border: solid 1px;
        padding: 5px;
    }
</style>
