<template>
  <v-container>
    <div v-if="module">
      <v-form ref="form">
        <v-row no-gutters>
          <v-col cols="8">
            <div v-for="(info, name) in module.config.input" :key="name">
              <ModuleInput
                :key="name"
                :name="name"
                :info="info"
                filled
                required
                dense
                accept=".csv"
                @setFile="onSetFile"
              />
              <v-row v-if="name == 'compounds_file'" no-gutters justify="end">
                <sample-download-button :filename="module.config.sample_file" />
              </v-row>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4">
            <v-select
              v-model="generationMethod"
              :items="generationMethods"
              item-value="value"
              item-text="text"
              label="Generation method"
              required
              :rules="[v => !!v || 'The prediction method is required']"
            >
              <template #append-outer>
                <v-tooltip right>
                  <template #activator="{ on, attrs }">
                    <v-icon class="help-icon" v-bind="attrs" v-on="on">mdi-help-circle-outline</v-icon>
                  </template>
                  <span v-html="methodHelp" />
                </v-tooltip>
              </template>
            </v-select>
          </v-col>
        </v-row>
        <v-row v-if="generationMethod === 'gx2mol'">
          <v-col cols="4">
            <v-autocomplete
              v-model="cell"
              :items="cells"
              label="Cell name"
              required
              :rules="[v => !!v || 'The cell name is required']"
            />
          </v-col>
        </v-row>
        <v-row v-else-if="generationMethod !== ''">
          <v-col cols="4">
            <v-autocomplete
              v-model="signature"
              :items="signatures"
              :item-text="
                (item) => convertToSentenceCase(item.key)
              "
              item-value="key"
              label="Cell / Tissue type"
              return-object
              required
              :rules="[v => !!v || 'The type of the input is required']"
              @change="changeKey"
            />
          </v-col>
          <v-col cols="4">
            <v-autocomplete
              v-show="targets.length > 0"
              v-model="target"
              :items="targets"
              :label="autocompleteLabel"
            >
              <template slot="selection" slot-scope="{ item }">
                {{ convertToSentenceCase(item) }}
              </template>
              <template slot="item" slot-scope="{ item }">
                {{ convertToSentenceCase(item) }}
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            v-if="generationMethod === 'linear'"
            cols="4"
          >
            <v-text-field
              v-model="epochs"
              label="Epoch"
              type="number"
              min="1"
              required
              :rules="[v => !!v || 'The number of epochs is required']"
            />
          </v-col>
          <v-col v-if="generationMethod !== ''" cols="4">
            <v-select
              v-model="geneAnnotation"
              :items="geneAnnotations"
              label="Gene Annotation"
              required
              :rules="[v => !!v || 'The gene annotation is required']"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col
            v-if="['bayesian', 'linear', 'transgan'].includes(generationMethod)"
            cols="4"
          >
            <v-text-field
              v-model="k"
              :label="srcMolFieldLabel"
              type="number"
              min="2"
              max="10"
              required
              :rules="[
                v => (v >= 2 && v <= 10) || 'The number of molecules must be between 2 and 10.',
                v => !!v || 'The number of molecules is required'
              ]"
            />
          </v-col>
          <v-col v-if="generationMethod === 'bayesian'" cols="4">
            <v-text-field
              v-model="max_iter"
              label="The number of max iterations in Bayesian optimization"
              type="number"
              min="1"
              max="1000"
              required
              :rules="[
                v => (v >= 1 && v <= 1000) || 'The number of iterations must be between 1 and 1000.',
                v => !!v || 'The number of iterations is required'
              ]"
            />
          </v-col>
        </v-row>
      </v-form>

      <ExecuteButton
        :disabled="!canSubmit"
        :validation-method="validate"
        :confirm-loading="loading"
        @confirm="onSubmit"
      />
      <div v-if="moduleName">
        <EstimatedRunTime :module="moduleName" />
      </div>
    </div>
  </v-container>
</template>

<script>
import router from '@/router';
import ModuleInput from '@/components/ModuleInput';
import { convertToSentenceCase } from '@/mixins/utils';
import ExecuteButton from '@/components/ExecuteButton.vue';
import SampleDownloadButton from '@/components/SampleDownloadButton.vue';
import EstimatedRunTime from '@/components/EstimateRunTime.vue';

export default {
  name: 'HitGenerationExecuteView',
  components: {
    ModuleInput,
    ExecuteButton,
    SampleDownloadButton,
    EstimatedRunTime
  },
  data() {
    return {
      id: this.$route.params.id,
      module: null,
      parameters: Object(),
      files: Object(),
      canSubmit: false,
      generationMethod: this.$route.query.generationMethod || '',
      generationMethods: [
        { text: 'Gx2Mol (default)', value: 'gx2mol' },
        { text: 'TransGAN', value: 'transgan' },
        { text: 'TransVAE with Bayesian optimization', value: 'bayesian' },
        { text: 'TransVAE with linear search', value: 'linear' }
        // { text: 'SELFIES-based VAE', value: 'selfies' }, // Currently not available
      ],
      signatures: [],
      targets: [],
      target: 'all',
      epochs: 1,
      geneAnnotation: '',
      geneAnnotations: ['gene_symbol', 'kegg_id'],
      LINCStype: '',
      cell: '',
      k: 2,
      max_iter: 500,
      cells: [],
      input: {},
      loading: false,
      signature: null
    };
  },
  computed: {
    methodHelp: function() {
      switch (this.generationMethod) {
      case '':
        return 'Please choose a generation method.';
      case 'bayesian':
        return `This model outputs the structures of <br>
                  molecules that are expected to induce the desired gene expression profile, <br>
                  using Bayesian optimization to explore the latent space of Transformer-based VAE.
`;
      case 'linear':
        return `This model outputs the structures of molecules that are expected to <br>
                  induce the desired gene expression profile, <br>
                  performing linear exploration of the latent space in Transformer-based VAE.

            `;
      case 'selfies':
        return `入力された遺伝子発現プロファイルを持つ化合物の構造を、<br>
                  SELFIES構造表現文字列から構築されたVAE を用いて、<br>
                  望ましい遺伝子発現プロファイルを持つことが期待される新規化合物の構造を出力します。`;
      case 'transgan':
        return `This model outputs the structures of <br>
                  molecules that are expected to induce <br>
                  the desired gene expression profile, <br>
                  using Transformer-based GAN.
          `;
      }
      return `This model outputs the structures of <br>
            molecules that are expected to induce the desired gene expression profile, <br>
            using RNN and features extracted by VAE.`;
    },
    srcMolFieldLabel: function() {
      if (this.generationMethod === 'linear') {
        return 'The number of source molecules used for Linear optimization';
      }
      if (this.generationMethod === 'bayesian') {
        return 'The number of source molecules used for Bayesian optimization';
      }
      if (this.generationMethod === 'transgan') {
        return 'The number of source molecules used for TransGAN optimization';
      }
      return '';
    },
    moduleName: function() {
      if (this.generationMethod === 'linear') {
        return 'Yamanaka model';
      } else if (this.generationMethod === 'bayesian') {
        return 'Bayesian';
      } else if (this.generationMethod === 'gx2mol') {
        return 'Gx2Mol';
      } else if (this.generationMethod === 'transgan') {
        return 'TransGAN';
      }
    },
    autocompleteLabel() {
      if (this.signature) {
        if (this.signature.key === 'cell_lines' || this.signature.key === 'Cell lines (imputed)') {
          return 'Cell';
        } else if (this.signature.key === 'tissues' || this.signature.key === 'Tissues (imputed)') {
          return 'Tissue';
        }
      }
      return 'Target';
    }
  },
  watch: {
    'generationMethod': function() {
      // Set default values on method change
      if (this.generationMethod === 'linear' && this.k === 2) {
        this.k = 3;
      }
      if (this.generationMethod === 'bayesian' && this.k === 3) {
        this.k = 2;
      }
    }
  },
  mounted() {
    this.checkLoggedIn(this.$session);
    const self = this;
    //! TODO: モデルが変わっても切り替えないので、モデルが変わったら再取得するようにする
    this.api.getMLModuleByName(
      'Structure Generation',
      function(module) {
        self.module = module;
        self.module.config = JSON.parse(self.module.config);
        const optionsData = self.module.config.input.signatures.options;
        Object.keys(optionsData).forEach((option) => {
          self.signatures.push({
            key: option,
            targets: optionsData[option].values || []
          });
        });
      },
      function(error) {
        console.log(error);
      }
    );
    this.api.getMLModuleByName(
      'Gx2Mol',
      function(module) {
        const config = JSON.parse(module.config);
        const cellData = config.input.signatures.options.cells.values;
        cellData.forEach(c => {
          self.cells.push({
            text: convertToSentenceCase(c),
            value: c
          });
        });
      },
      function(error) {
        console.log(error);
      }
    );
  },
  methods: {
    convertToSentenceCase,
    onSetFile(...args) {
      const [name, file] = args;
      if (file) {
        this.files[name] = file;
        this.canSubmit = true;
        this.input[name] = file.name;
      } else {
        this.files = Object();
        this.canSubmit = false;
      }
    },
    validate() {
      return this.$refs.form.validate();
    },
    onSubmit(visibility) {
      this.loading = true;
      this.$session.start();
      let cell = '';
      if (this.generationMethod === 'gx2mol') {
        cell = this.cell;
      } else {
        cell = this.targets.length > 0 ? this.target : 'all';
      }
      this.parameters = {
        cell: cell,
        LINCStype: this.LINCStype,
        genetype: this.geneAnnotation,
        epochs: this.epochs,
        k: this.k,
        max_iter: this.max_iter
      };
      this.input = {
        ...this.input,
        'Generation method': this.generationMethods.find(obj => obj.value === this.generationMethod).label,
        'Cell name': this.generationMethod === 'gx2mol' ? this.cells.find(cell => cell.value === this.cell).text : null,
        'Cell / Tissue type': this.generationMethod !== 'gx2mol' ? this.convertToSentenceCase(this.signature.key) : null,
        'Epoch': this.generationMethod === 'linear' ? this.epochs : null,
        'Gene Annotation': this.generationMethod !== '' ? this.geneAnnotation : null,
        'K': ['bayesian', 'linear', 'transgan'].includes(this.generationMethod) ? this.k : null,
        'The number of max iterations in Bayesian optimization': this.generationMethod === 'bayesian' ? this.max_iter : null
      };
      this.input[this.autocompleteLabel] = this.generationMethod !== '' ? this.target : null;
      const self = this;
      this.api.executeMLModule(
        { name: this.moduleName },
        { ...this.parameters, ...visibility },
        this.files,
        function(response) {
          let resultView = '';
          if (self.generationMethod === 'linear') {
            resultView = 'HitGenerationTransVAELinearSearchResultView';
          } else if (self.generationMethod === 'bayesian') {
            resultView = 'HitGenerationTransVAEBayesianResultView';
          } else if (self.generationMethod === 'transgan') {
            resultView = 'HitGenerationTransGANResultView';
          } else {
            resultView = 'HitGenerationResultView';
          }
          router.push({
            name: resultView,
            params: { id: response.id }
          });
          self.loading = false;
        },
        function(error) {
          console.log(error);
          self.loading = false;
        },
        this.input
      );
    },
    changeKey(e) {
      if (e.key.includes('imputed')) {
        this.LINCStype = 'imputed';
      } else {
        this.LINCStype = 'original';
      }
      if (e.key.toLowerCase().startsWith('cell')) {
        this.targets = this.signatures.find(s => s.key === 'cell_lines').targets;
      } else if (e.key.toLowerCase().startsWith('tissue')) {
        this.targets = this.signatures.find(s => s.key === 'tissues').targets;
      } else {
        this.targets = [];
      }
      this.cell = this.targets[0] || 'all';
    }
  }
};
</script>
