<template>
  <v-container>
    <div v-if="module">
      <v-form ref="form">
        <v-row>
          <v-col cols="8">
            <div v-for="(info, name) in module.config.input" :key="name">
              <ModuleInput
                :key="name"
                :name="name"
                :info="info"
                filled
                required
                dense
                accept=".csv"
                @setFile="onSetFile"
              />
              <v-row v-if="name == 'compounds_file'" no-gutters justify="end">
                <sample-download-button :filename="module.config.sample_file" />
              </v-row>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4">
            <v-autocomplete
              v-model="cell"
              :items="cells"
              label="Tissue"
              required
              :rules="[(v) => !!v || 'The cell name is required']"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4">
            <v-select
              v-model="geneAnnotation"
              :items="geneAnnotations"
              label="Gene Annotation"
              required
              :rules="[(v) => !!v || 'The gene annotation is required']"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="pattern"
              label="Scaffold pattern to be respected"
              placeholder="C(COC1C=C(C=NC=1)/C=C/C1=CC=NC=C1)NC/C=C/C1=CC=C(C=C1)(*)"
            />
          </v-col>
        </v-row>
      </v-form>
      <ExecuteButton :disabled="!canSubmit" :validation-method="validate" :confirm-loading="loading" @confirm="onSubmit" />
      <EstimatedRunTime module="Gx2Mol_LG" />
    </div>
  </v-container>
</template>

<script>
import router from '@/router';
import ExecuteButton from '@/components/ExecuteButton.vue';
import ModuleInput from '@/components/ModuleInput';
import { convertToSentenceCase } from '@/mixins/utils';
import SampleDownloadButton from '@/components/SampleDownloadButton.vue';
import EstimatedRunTime from '@/components/EstimateRunTime.vue';

export default {
  name: 'ExecuteFormGx2Mol',
  components: {
    ExecuteButton,
    ModuleInput,
    SampleDownloadButton,
    EstimatedRunTime
  },
  data() {
    return {
      id: this.$route.params.id,
      module: null,
      canSubmit: false,
      parameters: Object(),
      files: Object(),
      signatures: [],
      geneAnnotation: '',
      geneAnnotations: ['gene_symbol', 'kegg_id'],
      cell: '',
      cells: [],
      targets: [],
      target: 'all',
      pattern: '',
      loading: false,
      input: {}
    };
  },
  mounted() {
    const self = this;
    this.api.getMLModuleByName(
      'Gx2Mol_LG',
      function(module) {
        self.module = module;
        const config = JSON.parse(module.config);
        self.module.config = config;
        const cellData = config.input.signatures.options.cells.values;
        cellData.forEach((c) => {
          self.cells.push({
            text: convertToSentenceCase(c),
            value: c
          });
        });
      },
      function(error) {
        console.log(error);
      }
    );
  },
  methods: {
    convertToSentenceCase,
    onSetFile(...args) {
      const [name, file] = args;
      if (file) {
        this.files[name] = file;
        this.canSubmit = true;
        this.input[name] = file.name;
      } else {
        this.files = Object();
        this.canSubmit = false;
      }
    },
    onSubmit(visibility) {
      this.loading = true;
      const cell = this.cell;
      this.parameters = {
        cell: cell,
        genetype: this.geneAnnotation,
        pattern: this.pattern
      };
      const modelName = 'Gx2Mol_LG';
      this.input = {
        'Generation method': 'Gx2Mol',
        ...this.input,
        'Cell name': this.cells.find(cell => cell.value === this.cell).text,
        'Gene Annotation': this.geneAnnotation,
        'Scaffold pattern to be respected': this.pattern
      };
      this.api.executeMLModule(
        { name: modelName },
        { ...this.parameters, ...visibility },
        this.files,
        function(response) {
          router.push({
            name: 'LeadGenerationGx2MolResultView',
            params: { id: response.id }
          });
          self.loading = false;
        },
        function(error) {
          console.log(error);
          self.loading = false;
        },
        this.input
      );
    },
    validate() {
      return this.$refs.form.validate();
    }
  }
};
</script>
