<template>
  <ResultViewType1 show-filter/>
</template>

<script>
import ResultViewType1 from '@/components/ResultViews/ResultViewType1.vue';

export default {
  name: 'LeadGenerationGx2MolResultView',
  components: {
    ResultViewType1
  }
};
</script>
