<template>
  <v-card>
    <v-row v-if="filters.length !== 0" no-gutters>
      <v-col cols="2" class="text-h6 pl-3">
        Filters
      </v-col>
      <v-col cols="6">
        <v-row
          v-for="(filter, i) in filters"
          :key="`filter_${i}`"
          no-gutters
        >
          <v-checkbox
            v-model="selectedFilters"
            :value="filter.predicted_value_name"
            :label="`${filter.name} (${filter.predicted_value_name})`"
            :disabled="!execution.has_result"
            dense
            hide-details
          />
        </v-row>
      </v-col>
      <v-spacer />
      <v-col cols="4" align="right">
        <v-btn
          :disabled="selectedFilters.length == 0 || !hasFilteredResults"
          @click="downloadFilteredResults"
        >
          <v-icon dark light>mdi-download</v-icon>
          DL filtered results
        </v-btn>
        <v-tooltip top>
          <template #activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              small
              class="ml-1"
              v-on="on"
            >
              mdi-information-outline
            </v-icon>
          </template>
          <span>Download the results after applying the selected filters.</span>
        </v-tooltip>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import consts from '@/store/consts';

export default {
  name: 'FilterCard',
  props: {
    execution: {
      type: Object,
      required: true
    },
    filters: {
      type: Array,
      default: () => []
    },
    hasFilteredResults: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selectedFilters: this.filters.map((filter) => filter.predicted_value_name)
    };
  },
  watch: {
    selectedFilters: {
      handler() {
        this.$emit('selectFilters', this.selectedFilters);
      }
    }
  },
  mounted() {},
  methods: {
    downloadFilteredResults() {
      this.$emit('downloadFilteredResult', this.selectedFilters);
    },
    downloadAllResults() {
      this.$emit('downloadFilteredResult', []);
    }
  }
};
</script>
